import React from 'react';
import { useMatchMedia } from '@/modules/shared/hooks';
import { Banner, BannerImages } from '@/modules/banners';
import { IEvent } from '@/modules/events';
import { EventBlock } from '../event-block';
import { CategoryBlockHome } from '../../categories/category-block-home';
import styles from './banner-and-category-block.module.scss';

interface Props {
  events: IEvent[] | [];
  bannerList: BannerImages[] | [];
}

export const BannerAndCategoryBlock = ({ events, bannerList }: Props) => {
  const { isDesktop } = useMatchMedia();

  return (
    <section className={styles.banner_and_category_block}>
      {isDesktop ? (
        <>
          <EventBlock events={events} />
          <Banner bannerImages={bannerList} isFullWidth />
          <CategoryBlockHome />
        </>
      ) : (
        <>
          <Banner bannerImages={bannerList} isFullWidth />
          <div className={styles.event_and_category}>
            <EventBlock events={events} />
            <CategoryBlockHome />
          </div>
        </>
      )}
    </section>
  );
};
